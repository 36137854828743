/* .play-container {
  position: absolute;
  height: 700px;
  width: 620px;
  left: 40px;
  top: 60px;
} */
/* .game-map-container {
  border: 2px solid white;
} */
.dm-elements-area {
	background-color: #4a4a4a99;
	height: 70px;
	position: absolute;
	bottom: 0;
	left: 135px;
	width: 650px;
	/* border: 1px solid #d2e592ba; */
	border-radius: 7px;
}
.play-area {
	height: 100%;
	position: relative;
	border: 10px solid white;
}

.player-element.dm {
	cursor: grab;
}
.player-element {
	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none; /* Safari */
	-khtml-user-select: none; /* Konqueror HTML */
	-moz-user-select: none; /* Old versions of Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
	user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
	height: 18px;
	width: 18px;
	border-radius: 50%;
	color: white;
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
}

.element.dm {
	cursor: grab;
}
.element {
	background-color: tomato;
	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none; /* Safari */
	-khtml-user-select: none; /* Konqueror HTML */
	-moz-user-select: none; /* Old versions of Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
	user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
	height: 18px;
	width: 18px;
	border-radius: 50%;
	color: white;
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
}

.element-dead {
	background-color: black;
	height: 18px;
	width: 18px;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	cursor: pointer;
}
.activeBtn {
	background: green;
	border: none;
	color: white;
}
.game-map-wrap {
	pointer-events: none;
}
.map-player-popup .ant-popover-title {
	min-width: 130px !important;
}
