.drop-down-container {
	background: #3a6186;
	background: -webkit-linear-gradient(to top, #89253e, #3a6186);
	background: linear-gradient(to top, #89253e, #3a6186);
	padding: 15px;
	padding-left: 20px;
	display: flex;
	flex-direction: column;
	align-items: center;
	border-radius: 5px;
}
.menu-items-container {
	display: flex;
	flex-direction: column;
	/* text-align: center; */
	/* min-width: 100px;
  width: 100%; */
}
.drop-down-container.no-dm .menu-items-container {
	min-width: 150px;
}
.menu-items {
	min-width: 130px;
	border: none;
	font-weight: 600;
	/* background: rgb(241, 238, 238); */
	border-radius: 20px;
	/* color: #3a6186; */
	color: #47525d;
	cursor: pointer;
	padding: 3px 0;
}
.menu-items:not(.last) {
	margin-bottom: 7px;
}
.menu-items:hover {
	/* background: rgb(240, 236, 236); */
	color: #000;
}
.volumeBtn {
	font-size: 19px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.total-notification-bell-icon {
	color: red !important;
	position: absolute;
	top: 5px;
	right: 5px;
}

.custom-simple-modal .ant-modal-confirm-btns {
	display: none;
}

.simple-modal-icon-wrapper {
	border-radius: 100%;
	-webkit-border-radius: 100%;
	-moz-border-radius: 100%;
	-ms-border-radius: 100%;
	-o-border-radius: 100%;
	background-color: #9195ff;
}
