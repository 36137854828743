@import '~antd/dist/antd.less';

.primary-modal .container {
	min-height: 520px;
}
.note-container {
	padding: 8px 12px;
}

.ant-modal-width-mid {
	width: 850px !important;
}
.ant-modal-width-full {
	width: calc(100vw - 5%) !important;
}

.custom-modal-login {
	background-image: url('../src/assets/RolePlayMagic_white.png');
	background-position: center 50px;
	background-repeat: no-repeat;
	background-size: 240px;
	background-color: transparent;
}
.custom-modal-login.no-modal-close-btn .ant-modal-close-x {
	display: none;
}
.custom-modal-login .ant-modal-body {
	padding-left: 28px;
	padding-right: 28px;
}
.custom-modal-login .ant-modal-content {
	top: 100px;
	color: #fff;
	background-color: #26272a;
	margin-bottom: 150px;
}

.ant-modal-content {
	border-radius: 10px;
}

.custom-modal-login .ant-modal-footer,
.custom-modal-login .ant-modal-header {
	background-color: #26272a;
	border-bottom: none;
	border-top: none;
	color: #fff;
}
.custom-modal-login .ant-form-item-explain {
	font-size: 12px;
	min-height: 16px;
	line-height: auto;
}
.custom-modal-login .ant-form-item {
	margin-bottom: 8px;
}
.custom-modal-login .ant-form-vertical .ant-form-item-label {
	padding-bottom: 4px;
}
.custom-modal-login .ant-modal-title,
.custom-modal-login .ant-modal-close-icon,
.custom-modal-login h1,
.custom-modal-login p,
.custom-modal-login label {
	color: #fff;
}
.custom-modal-login .ant-btn.ant-btn-primary {
	background: #98a4b2;
	border-color: #98a4b2;
}
.ant-modal-header {
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
}
.custom-modal-login .ant-modal-footer {
	padding: 10px 16px;
	padding-bottom: 16px;
}

.ant-modal-footer {
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
}

/* Antd input field customization */
.ant-input,
.ant-input-affix-wrapper {
	padding: 6px 11px;
	border-radius: 4px;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
	border-radius: 4px;
	height: 36px;
	padding-top: 2px;
}
/* End input field customization */

/* Antd button */
.ant-btn {
	font-weight: 600;
	height: 36px;
	border-radius: 4px;
}

.scrollable-one {
	padding: 10px;
	height: 440px;
	overflow-y: auto;
}
.scrollable-two {
	padding: 10px;
	height: 375px;
	overflow-y: auto;
}
.scrollable-three {
	padding: 10px;
	height: 450px;
	overflow-y: auto;
}
.scrollable-one::-webkit-scrollbar,
.scrollable-two::-webkit-scrollbar {
	width: 4px;
}
.scrollable-one::-webkit-scrollbar-thumb,
.scrollable-two::-webkit-scrollbar-thumb {
	background: rgb(194, 194, 194);
}
.scrollable-lib {
	padding: 10px;
	height: 490px;
	overflow-y: auto;
}
.scrollable-lib::-webkit-scrollbar {
	width: 4px;
}
.scrollable-lib::-webkit-scrollbar-thumb {
	background: rgb(194, 194, 194);
}
.settings-dropdown {
	background-color: #c3cfd9;
	padding: 14px 6px;
	width: 120px;
}
.settings-dropdown a {
	text-decoration: underline;
	color: #555555;
}
.primary-border {
	border: 2px solid #abb5be;
}
/* .ant-input,
.ant-input-number,
.ant-select-selector {
  border: 2px solid #abb5be !important;
}
.ant-input:hover {
  border-right-width: 2px !important;
} */
.fill-height {
	height: -webkit-fill-available;
}
.imageBox {
	position: relative;
	width: 146px;
	height: 186px;
}
.image-box-bordered {
	box-shadow: 0px -5px 5px 5px #000000bf;
	border: 4px solid #8fbc8f;
}
.imageBox:hover .image-action-buttons {
	opacity: 1;
	transition: ease-in-out 0.4s;
}
.imageBox .image-action-buttons {
	position: absolute;
	top: -8px;
	right: -8px;
	opacity: 0;
	display: flex;
	justify-content: space-between;
	width: 55px;
	transition: ease-in-out 0.4s;
}
.scene-title-wrap {
	position: absolute;
	bottom: 0px;
	width: 99%;
}
.scene-title-wrap .scene-title {
	/* background-color: #666666e0; */
	/* display: inline-block; */
	margin: 0;
	padding: 2px 10px;
	font-size: 13px;
	border-radius: 2px;
	color: #ffffff;
	/* border: 1px solid #ffffff; */
	text-shadow: 3px 3px 2px black;
	min-height: 46px;
}
.apply-scene-wrap {
	position: absolute;
	opacity: 0;
	transition: ease-in-out 0.4s;
}
.apply-scene-wrap-tick-mark {
	position: absolute;
	opacity: 1;
	transition: ease-in-out 0.4s;
}
.imageBox:hover .apply-scene-wrap {
	opacity: 1;
	transition: ease-in-out 0.4s;
}
.background-settings {
	background-position: center center;
	background-size: cover;
	background-repeat: no-repeat;
}
.background-settings-v2 {
	background-position: center center;
	background-size: contain;
	background-repeat: no-repeat;
	background-color: white;
}
.white {
	color: #ffffff;
}
.custom-button-settings {
	background-position: center center;
	background-size: contain;
	background-repeat: no-repeat;
	background-color: transparent;
	border: none;
	padding: 0px 0px 5px 0px;
	margin: 0;
	cursor: pointer;
}
.large-button-settings {
	width: 130px;
	height: 40px;
	cursor: pointer;
}
.small-button-settings {
	width: 39px;
	height: 40px;
	cursor: pointer;
}
.game-map-container {
	position: relative;
	height: 880px;
	width: 820px;
}
.game-map {
	position: absolute;
	top: 67px;
	left: 50px;
	height: 712px;
	width: 722px;
}
.game-map-overlay {
	opacity: 0.7;
}
.game-map-wrap {
	position: absolute;
}
.game-title {
	position: absolute;
	top: 25px;
	width: 100%;
	text-align: center;
}
.dm .player-container {
	cursor: pointer;
}
.player-container {
	position: relative;
	height: 127px;
	width: 100px;
	flex-shrink: 0;
	margin-right: 13px;
	margin-bottom: 10px;
}
.player-container .player {
	position: absolute;
	top: 12px;
	left: 12px;
	height: 100px;
	width: 75px;
}
.player-container .dm-image {
	position: absolute;
	top: 12px;
	left: 6px;
	height: 126px;
	width: 96px;
	border-radius: 50px;
}
.player-container .player-box {
	position: absolute;
}
.player-container .player-name {
	position: absolute;
	bottom: 3px;
}
.player-container .character-name {
	position: absolute;
	top: 0px;
}
.player-container .player-text {
	text-align: center;
	font-size: 9px;
}
.player-container .player-stats-hp {
	position: absolute;
	bottom: 4px;
	left: 0px;
	height: 15px;
	width: 18px;
	line-height: 17px;
}
.player-container .player-stats-ac {
	position: absolute;
	bottom: 4px;
	right: 1px;
	height: 15px;
	width: 18px;
	line-height: 17px;
}
.player-container .player-stats-num {
	position: absolute;
	top: 0px;
	left: 0px;
	height: 18px;
	width: 18px;
	border-radius: 50%;
	line-height: 17px;
}
.my-player-container {
	position: relative;
	height: 317.5px;
	width: 250px;
	flex-shrink: 0;
	cursor: pointer;
	margin-right: 10px;
}
.my-player-container .my-player {
	position: absolute;
	top: 25px;
	left: 35px;
	height: 255px;
	width: 180px;
}
.my-player-container .dm-image {
	position: absolute;
	top: 32px;
	left: 16px;
	height: 270px;
	width: 204px;
	border-radius: 90px;
}
.my-player-container .my-player-box {
	position: absolute;
}
.my-player-container .my-player-name {
	position: absolute;
	bottom: 13px;
}
.my-player-container .my-character-name {
	position: absolute;
	top: 5px;
}
.my-player-container .my-player-text {
	text-align: center;
	font-size: 14px;
}
.my-player-container .my-player-stats-hp {
	position: absolute;
	bottom: 9px;
	left: 0px;
	height: 40px;
	width: 45px;
	line-height: 46px;
}
.my-player-container .my-player-stats-ac {
	position: absolute;
	bottom: 9px;
	right: 0px;
	height: 40px;
	width: 45px;
	line-height: 46px;
}
.my-player-container .my-player-stats-num {
	position: absolute;
	top: 3px;
	left: 3px;
	height: 39px;
	width: 39px;
	line-height: 39px;
	border-radius: 50%;
}
.players-width {
	width: 340px;
}
.advert-Box {
	margin-bottom: 8px;
	height: 50px;
	width: 100%px;
	margin: 0px 0px 0px auto;
	background-color: white;
	line-height: 50px;
	font-weight: bold;
	text-align: center;
}
.map-grid {
	position: absolute;
	top: 72px;
	left: 50px;
	height: 712px;
	width: 722px;
	opacity: 0.4;
	background-position: center center;
	background-size: cover;
	background-repeat: no-repeat;
}

.playerInfoLogin {
	display: flex;
	justify-content: space-between;
	// align-items: center;
}
.nameAndWelcome {
	flex: 1;
	// text-align: center;
}
.nameAndWelcome h3 {
	font-size: 20px;
	font-weight: bold;
}
.nameAndWelcome span,
.nameAndWelcome p {
	font-size: 17px;
	font-weight: 500;
}
.image-upload-custom .ant-upload.ant-upload-drag.draggerr {
	border: 2px dashed #e5e5e5 !important;
	background-color: #f9f9f9;
	border-radius: 15px;
	width: 100% !important;
}
.image-upload-custom .ant-upload.ant-upload-drag.draggerrCharacterView {
	border: 2px dashed rgb(195, 195, 195) !important;
	background-color: #f9f9f9;
	border-radius: 15px;
	width: 225px !important;
}
.draggerButton {
	background-color: #cfcfcf;
	border: none;
	color: white;
	border-radius: 7px;
	cursor: pointer;
	font-weight: 600;
	padding: 7px 18px;
}
.draggerOR {
	display: flex;
	justify-content: center;
}
.image-upload-custom .ant-upload p {
	margin: 0 20px 6px 20px !important;
}
.draggerr h2 {
	width: 50%;
	text-align: center;
	border-bottom: 1px solid #cfcfcf;
	color: #cfcfcf;
	font-size: 17px;
	line-height: 0.1em;
	margin: 10px 0 20px;
}
.dragger-text {
	font-weight: 600;
	color: #a2a2a2;
}
.draggerr h2 span {
	background-color: #f9f9f9;
	padding: 0 10px;
}

@media only screen and (max-width: 1429px) {
	.custom-flex {
		flex-wrap: wrap;
	}
}
.highlighted input,
.highlighted textarea {
	border: 2px solid #00ff70;
}

.highlighted-error input,
.highlighted-error textarea {
	border: 2px solid #e40000;
}
@keyframes dice-animation {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

.animated-dice {
	animation-name: dice-animation;
	animation-duration: 0.1s;
	animation-iteration-count: infinite;
}
/* .characters-list-wrapper  */
.ant-popover-inner-content {
	background-color: #191b1f;
	color: #ffffff;
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
}

.ant-popover-inner {
	border-radius: 5px;
}

.dice-history-btn-dm-v1 {
	right: 38px;
	position: absolute;
	top: 48px;
	background: transparent;
	border: none;
}

.dice-history-btn-dm-v2 {
	right: 0px;
	position: absolute;
	top: 0px;
	background: transparent;
	border: none;
}

.dice-history-btn-opponent {
	right: 0px;
	position: absolute;
	top: 0px;
	background: transparent;
	border: none;
}

.dice-history-btn-player {
	right: 0px;
	position: absolute;
	top: 0px;
	background: transparent;
	border: none;
}
.dice-history-btn-dm-v1:hover,
.dice-history-btn-dm-v1:focus,
.dice-history-btn-dm-v1:active,
.dice-history-btn-dm-v2:hover,
.dice-history-btn-dm-v2:focus,
.dice-history-btn-dm-v2:active,
.dice-history-btn-opponent:hover,
.dice-history-btn-opponent:focus,
.dice-history-btn-opponent:active,
.dice-history-btn-player:hover,
.dice-history-btn-player:focus,
.dice-history-btn-player:active {
	background: transparent;
}
.dice-number {
	position: absolute;
	left: 22px;
	bottom: 20px;
	color: white;
	font-weight: bold;
	font-size: 22px;
	box-shadow: 0px 0px 6px 8px #00000085;
	line-height: 0px;
}
.dm-character-image-glow {
	box-shadow: 0px 0px 26px 9px rgb(255 255 255 / 78%);
}
.character-image-glow {
	box-shadow: 0px 0px 14px 14px rgb(255 255 255 / 78%);
}
.main-character-image-glow {
	box-shadow: 0px 0px 15px 30px rgb(255 255 255 /75%);
}
/* #zmmtg-root {
  display: none;
} */
.element-display-block {
	display: block;
}
.element-display-none {
	display: none;
}

.image-upload-custom .ant-upload p {
	margin: 20px;
}
.image-upload-custom .ant-image {
	width: 158px !important;
	height: 160px;
}
.image-upload-custom .ant-upload.ant-upload-drag {
	width: 170px !important;

	border: 1px solid #d9d9d9 !important;
}
.image-upload-custom .ant-image-img {
	height: 170px;
	object-fit: contain;
	width: 90%;
	margin-left: 8px;
	margin-top: -7px;
}

.image-upload-custom .ant-upload-list {
	display: none !important;
}

.custom-badge {
	position: relative;
}
.custom-badge::after {
	content: '';
	height: 5px;
	width: 5px;
	background-color: #ff0000;
	border-radius: 50%;
	display: inline-block;
	position: absolute;
	top: 5px;
	left: 3px;
}
.edit-dp {
	position: absolute;
	bottom: 4px;
	right: 4px;
}
.notes .ant-input {
	pointer-events: none;
}

.test-p .ant-popover-inner {
	background: transparent;
}

.test-p .ant-popover-inner-content {
	background: transparent;
}

.test-p .ant-popover-title {
	display: none;
}

/* Iframe */
.video-panel video {
	width: 500px !important;
	max-width: 90% !important;
}

.character-stats-label {
	font-size: 11px;
	font-weight: bold;
}

.new-library-card {
	height: 245px;
	width: 220px;
	background-color: #e6e6e6;
	border-radius: 12px;
	box-shadow: 0px 5px 9px 1px #00000038;
	margin-bottom: 30px;
	margin-right: 12px;
}

.new-lib-title {
	text-align: center;
	background-color: white;
	margin-bottom: 10px;
	border-top-right-radius: 8px;
	border-top-left-radius: 8px;
	font-weight: bold;
	min-height: 20px;
}

.new-lib-image {
	background-size: cover;
	height: 185px;
	width: 160px;
	border: 2px solid #6f6f6f;
	margin: 0 auto;
	background-position: center;
	background-repeat: no-repeat;
}
.new-lib-del {
	text-align: right;
	margin-right: 6px;
}

.custom-add-button-wrap {
	height: 100%;
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: center;
}

.custom-add-button-wrap .custom-add-button {
	background: none;
	color: #525252;
	width: 180px;
	height: 50px;
	border-radius: 20px;
	font-size: 18px;
	font-weight: bold;
	border: 1px solid silver;
	cursor: pointer;
	transition: ease-in-out 0.4s;
}

.custom-add-button-wrap .custom-add-button:hover {
	background: #525252;
	color: #d3d3d3;
	transition: ease-in-out 0.4s;
}

.custom-add-button-wrap .custom-add-button:disabled {
	background: #c9c9c9;
	color: black;
}

.new-library-card-v2 {
	height: 195px;
	width: 300px;
	// background-color: #edeff2;
	border-radius: 7px;
	margin-bottom: 30px;
	margin-right: 12px;
	padding: 15px;
	display: flex;
	// border: 1px solid #a7a5a5;
}

.box-1 {
	flex-shrink: 0;
}

.box-2 {
	flex-grow: 1;
	margin-left: 12px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.new-lib-title-v2 {
	font-weight: bold;
	font-size: 15px;
}

.new-lib-image-v2 {
	background-size: cover;
	height: 165px;
	width: 135px;
	border: 2px solid #6f6f6f;
	background-position: center;
	background-repeat: no-repeat;
}

.new-lib-del-v2 {
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
}

.applied-badge {
	border: 2px solid #2b956f;
	border-radius: 20px;
	font-size: 12px;
	font-weight: bold;
	color: #2b956f;
	padding: 4px 8px;
	line-height: 15px;
	background-color: #e5f9ee;
	display: inline-block;
}
.remove-badge {
	border: 2px solid #d30000;
	border-radius: 20px;
	font-size: 12px;
	font-weight: bold;
	color: #d30000;
	padding: 4px 8px;
	line-height: 15px;
	background-color: #ffc8c8;
	display: inline-block;
}

.apply-button {
	border: 2px solid #996a0f;
	border-radius: 20px;
	font-size: 12px;
	font-weight: bold;
	color: #996a0f;
	background-color: #fffaeb;
	height: auto;
}

.new-lib-del-v2 .action-card {
	font-size: 9px;
}

.tes {
	border-radius: 10px;
	background-color: #dee8f4;
}

.notification-bell-icon {
	color: red !important;
}

.messenger-chat-input {
	border-radius: 35px;
	padding-left: 16px;
	padding-right: 6px;
	padding-top: 4px;
	padding-bottom: 4px;
	background-color: #dee2e6;
	.ant-input {
		background-color: transparent !important;
	}
}

.chat-timestamp {
	margin-top: 3px;
	font-size: 10px;
	opacity: 0.8;
}

.standard-card {
	box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
	border-radius: 5px;
}

.secret-roll-switch {
	.ant-switch-handle {
		width: 16px;
		height: 16px;
	}
}

.grabbable {
	cursor: move; /* fallback if grab cursor is unsupported */
	cursor: grab;
	cursor: -moz-grab;
	cursor: -webkit-grab;
	padding: 0 5px;
	color: rgba(0, 0, 0, 0.45);
}

/* (Optional) Apply a "closed-hand" cursor during drag operation. */
.grabbable:active {
	color: rgba(0, 0, 0, 0.9);
	cursor: grabbing;
	cursor: -moz-grabbing;
	cursor: -webkit-grabbing;
}

/* Antd modal bg click */

.primary-modal {
	pointer-events: none;
}
.ant-modal {
	pointer-events: all;
}


/* Others */
.blink_me {
  animation: blinker 2s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}
@primary-color: #234175;@error-color: #BC6363;@red-1: #BC6363;